import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  items = [];
  showSidenav: boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth > 600) {
      this.showSidenav = true;
    } else {
      this.showSidenav = false;
    }
    console.log('resize:', event.target.innerWidth)
  }

  constructor(private apiService: ApiService) {
    if(window.innerWidth > 600) {
      this.showSidenav = true;
    } else {
      this.showSidenav = false;
    }
  }

  ngOnInit() {
    this.apiService.getPortfolio().subscribe(result => {
      this.items = result;
    });
  }

  toggleSideNav() {
    this.showSidenav = !this.showSidenav;
  }

  swapPic(project, pic) {
    project.img = pic.image;
  }
  
  toggleMore(project) {
    if(project.showing) {
      project.showing = undefined;
    } else {
      project.showing = project.other_images.length;
    }
  }

}
