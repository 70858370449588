import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'web';

  navLinks = [{
    path: '/',
    label: 'Home'
  },{
    path: '/resume',
    label: 'Resume'
  },{
    path: '/portfolio',
    label: 'Portfolio'
  }]

  showMenu: boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth > 800) {
      this.showMenu = true;
    } else {
      this.showMenu = false;
    }
    console.log('resize:', event.target.innerWidth)
  }

  constructor() {
    if(window.innerWidth > 800) {
      this.showMenu = true;
    } else {
      this.showMenu = false;
    }
  }
}
