import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUrl = 'https://api.bpomerenke.com';
  // baseUrl = 'http://localhost:8000';

  constructor(private http: HttpClient) { }

  getPortfolio(): Observable<any>{
    return this.http.get(`${this.baseUrl}/portfolio/`);
  }

  getPics(): Observable<any>{
    return this.http.get(`${this.baseUrl}/pics/`);
  }
}
