import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss']
})
export class PicturesComponent implements OnInit {
  items = [{
    title: '2012',
    galleries: [
      {
        title: 'Details',
        description: 'The little things the day of our wedding',
        img: 'http://bpomerenke.com/showImage.php?id=1791',
        count: 15
      },
      { 
        title: 'Japanese Garden', 
        description: 'Pictures at the Japanese Garden', 
        img: 'http://bpomerenke.com/showImage.php?id=2742',
        count: 24
      },
      { 
        title: 'Bride and Groom',
        description: 'Pictures before our wedding',
        img: 'http://bpomerenke.com/showImage.php?id=1111',
        count: 14
      }
    ]
  },{
    title: '2011',
    galleries: [
      {
        title: 'San Francisco and Napa Valley',
        description: 'Trip we took after our engagement visiting friends and enjoying wine country',
        img: 'http://bpomerenke.com/showImage.php?id=3804',
        count: 33
      },
      { 
        title: 'Memphis', 
        description: 'Trip we took to see graceland', 
        img: 'http://bpomerenke.com/showImage.php?id=3676',
        count: 12
      }
    ]
  },{
    title: '2010',
    galleries: [
      { 
        title: 'Forbidden City', 
        description: 'Checking out the Forbidden City in Beijing', 
        img: 'http://bpomerenke.com/showImage.php?id=2507' ,
        count: 42
      },
      {
        title: 'Great Wall',
        description: 'Checking out the Great Wall of China',
        img: 'http://bpomerenke.com/showImage.php?id=2653',
        count: 23
      }
    ]
  },{
    title: '2005',
    galleries: [
      { 
        title: 'Europe 05', 
        description: 'Backpacking throught Europe before studying abroad', 
        img: 'http://bpomerenke.com/showImage.php?id=2194',
        count: 74
      }
    ]
  }]
  constructor(private apiService: ApiService) { 
    if(window.innerWidth > 600) {
      this.showSidenav = true;
    } else {
      this.showSidenav = false;
    }
  }

  ngOnInit() {
    this.apiService.getPics().subscribe(result => {
      this.items = result.data;
    })
  }
  showSidenav: boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth > 600) {
      this.showSidenav = true;
    } else {
      this.showSidenav = false;
    }
    console.log('resize:', event.target.innerWidth)
  }

  toggleSideNav() {
    this.showSidenav = !this.showSidenav;
  }

}
