import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { 
  MatInputModule,
  MatTableModule, 
  MatPaginatorModule, 
  MatSortModule, 
  MatButtonModule, 
  MatGridListModule, 
  MatToolbarModule, 
  MatCardModule,
  MatTabsModule,
  MatSelectModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatMenuModule,
  MatIconModule
} from '@angular/material';
import { ResumeComponent } from './resume/resume.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { HomeComponent } from './home/home.component';
import { PicturesComponent } from './pictures/pictures.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    ResumeComponent,
    PortfolioComponent,
    HomeComponent,
    PicturesComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatTableModule, 
    MatPaginatorModule, 
    MatSortModule, 
    MatButtonModule, 
    MatGridListModule, 
    MatToolbarModule, 
    MatCardModule,
    MatTabsModule, 
    MatSelectModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
